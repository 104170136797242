@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply text-gray-900 dark:text-gray-100;
  }

  a {
    @apply underline;
  }

  a:hover {
    @apply no-underline;
  }

  a.login {
    @apply text-gray-200;
  }

  h1, h1 a {
    @apply py-1 font-serif text-3xl text-gray-800 no-underline dark:text-gray-300;
  }

  h2 {
    @apply pt-2 pb-1 font-sans text-xl font-bold text-blue-600 dark:text-blue-300;
  }

  h3 {
    @apply text-lg;
  }

  p {
    @apply py-2;
  }

  label {
    @apply inline-block pt-4 font-bold text-gray-900 dark:text-gray-100;
  }

  form.standard input[type=text], form.standard input[type=email], form.standard input[type=password], form.standard input[type=number], form.standard textarea, form.standard select {
    @apply px-3 py-2 leading-tight text-gray-700 border appearance-none;
  }

  form.standard input[type=text]:hover, form.standard input[type=email]:hover, form.standard input[type=password]:hover, form.standard input[type=number]:hover, form.standard textarea:hover,
  form.standard select:hover {
    @apply outline-none ring;
  }

  form.standard input[type=submit], a.button, input.button, button {
    @apply inline-block px-4 py-2 font-bold no-underline;
  }

  div.button {
    @apply px-4 py-2 font-bold no-underline cursor-pointer;
  }

  form.standard input[type=submit]:focus, a.button:focus, input.button:focus, button:focus  {
    @apply outline-none ring;
  }

  form.standard input[type=submit], .button-blue {
    @apply text-gray-100 bg-blue-500 border border-blue-500 dark:bg-blue-900 dark:border-blue-900 dark:text-gray-400;
  }

  form.standard input[type=submit]:hover, .button-blue:hover {
    @apply text-gray-100 bg-blue-700 border-blue-700 dark:bg-blue-800 dark:border-blue-800;
  }

  .button-red {
    @apply text-gray-100 bg-red-500 border border-red-500;
  }

  .button-red:hover {
    @apply text-gray-100 bg-red-700 border-red-700;
  }

  .button-green {
    @apply text-gray-100 bg-green-500 border border-green-500;
  }

  .button-green:hover {
    @apply text-gray-100 bg-green-700 border-green-700;
  }

  .button-orange {
    @apply text-gray-700 bg-orange-500 border border-orange-500;
  }

  .button-orange:hover {
    @apply text-gray-100 bg-orange-700 border-orange-700;
  }

  .button-gray {
    @apply text-gray-100 bg-gray-600 border border-gray-600;
  }

  .button-gray:hover {
    @apply text-gray-100 bg-gray-700 border-gray-700;
  }

  .button-white {
    @apply text-gray-700 bg-white border border-gray-700;
  }

  .button-white:hover {
    @apply bg-gray-100;
  }

  .button-transparent {
    @apply text-gray-700 border border-gray-700 dark:text-gray-400 dark:border-gray-400;
  }

  .button-transparent:hover {
    @apply text-gray-900 border-gray-900 dark:border-gray-300 dark:text-gray-300;
  }

  .h2-index {
    @apply mb-2 font-sans text-xl font-semibold;
  }

  .box-index {
    @apply p-4 border border-solid grid;
  }

  .button-index {
    @apply block p-4 mt-8 text-center text-white no-underline place-self-end;
  }

  .content {
    @apply px-4 py-2 bg-white;
  }

  .content-centered {
    @apply content-center h-full pb-8 text-center grid;
  }

  .content-centered h1 {
    @apply mb-4;
  }

  .content-centered p {
    @apply text-lg;
  }

  .content-centered br {
    @apply hidden lg:inline;
  }

  .content-two-columns {
    @apply grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16;
  }

  .content-two-columns-right {
    @apply self-start p-4 bg-gray-100 border border-gray-400 border-solid;
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('./fonts/icomoon.eot?938dck');
  src:  url('./fonts/icomoon.eot?938dck#iefix') format('embedded-opentype'),
    url('./fonts/icomoon.ttf?938dck') format('truetype'),
    url('./fonts/icomoon.woff?938dck') format('woff'),
    url('./fonts/icomoon.svg?938dck#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info-circle-solid:before {
  content: "\e900";
}
.icon-moon-solid:before {
  content: "\e901";
}
.icon-sun-solid:before {
  content: "\e902";
}
.icon-redo-solid:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-external-link:before {
  content: "\e905";
}

[role='tooltip'].popup-content {
  background-color: #edf2f7;
  color: #1a202c;
  border-color: #1a202c;
  width: 200px;
}

.dark [role='tooltip'].popup-content {
  background-color: #1a202c;
  color: #f7fafc;
  border-color: #f7fafc;
  width: 200px;
}
